import self from "../img/self.png"

export let colors = ["rgb(1,74,173)", "rgb(255,144,76)"];
/*
I highly recommend using a gradient generator like https://gradientgenerator.paytonpierce.dev/ to generate a pair of colors that you like.
These colors will be used to style your name on the homepage, the background of your picture, and some other accents throughout
the site.
 */


/*
So let's get started! Some of the info below is pretty self-explanatory, like 'firstName' and 'bio'. I'll try to explain anything
that might not be obvious right off the bat :) I recommend looking at the template example live using "npm start" to get an idea
of what each of the values mean.
 */

export const info = {
    welcometitle: "Olá, seja bem vinda!",
    firstName: "Eu sou Maria Lúcia",
    lastName: "",
    initials: "", // the example uses first and last, but feel free to use three or more if you like.
    position: "Sou psicóloga e Consteladora familiar",
    selfPortrait: self, // don't change this unless you want to name your self-portrait in the "img" folder something else!
    gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
    baseColor: colors[0],
    miniBio: [ // these are just some "tidbits" about yourself. You can look at mine https://paytonjewell.github.io/#/ for an example if you'd like
        {
            emoji: '📚',
            text: 'Psicoterapia'
        },
        {
            emoji: '👨‍👩‍👧‍👧',
            text: 'Constelação Familiar'
        },
        {
            emoji: '🌎',
            text: 'Atendimento on-line'
        },
        {
            emoji: "🎙",
            text: "Podcast - Minuto da mudança"
        },
        {
            emoji: "📧",
            text: "maria@mariacambraia.com"
        }
    ],
    services: [
        {
            title: "Psicoterapia",
            description: "É um tratamento colaborativo, guiado por técnicas e abordagens psicológicas embasada pela metodologia da ACT, tem o objetivo de diminuir desconfortos internos e/ou proporcionar autoconhecimento! Esse processo consiste em sessões individuais e semanais com duração de até 1 hora! "
        },
        {
            title: "Constelação familiar",
            description: "Técnica terapêutica que auxilia o processo de autoconhecimento e resolução de problemas. \nTem como base a metodologia sistêmica e atua de forma mais pontual! \nEssa técnica é representada por bonecos e é preciso ter um sintoma/queixa já pré-estabelecida para ser utilizada.\nConsiste em uma sessão (única) de até 1 hora e meia."
            
        },
        {
            title: "Grupo (Re)Viver",
            description: "Grupo criado para apoiar o autoconhecimento de mães que buscam melhorar a relação consigo mesmas para assim ter uma relação mais saudável com seja filhos."
        },
        
    ],
    socials: [
        {
            link: "https://api.whatsapp.com/send?phone=17065729494",
            icon: "fa fa-whatsapp",
            label: 'whatsapp'
        },
        {
            link: "https://www.instagram.com/marialucia.cambraia/",
            icon: 'fa fa-instagram',
            label: 'instagram'
        },
        {
            link: "https://open.spotify.com/show/48dHrw9NjwyvTXBBrkyzz3?si=e0aa4f7bf1374b36",
            icon: "fa fa-spotify",
            label: 'Minuto da mudança no spotify'
        }
// Feel free to remove any of these that you don't have. I'll write an FAQ on how to add new ones later, let me know if you have a request for an icon!
// Just change the links so that they lead to your social profiles.

    ],
    bio: "Linha 1\nLinha 2\nLinha 3",
    skills:
        {
            proficientWith: ['javascript', 'react', 'git', 'github', 'bootstrap', 'html5', 'css3', 'figma'],
            exposedTo: ['nodejs', 'python', 'adobe illustrator']
        }
    ,
    hobbies: [
        {
            label: 'reading',
            emoji: '📖'
        },
        {
            label: 'theater',
            emoji: '🎭'
        },
        {
            label: 'movies',
            emoji: '🎥'
        },
        {
            label: 'cooking',
            emoji: '🌶'
        }
// Same as above, change the emojis to match / relate to your hobbies or interests.
// You can also remove the emojis if you'd like, I just think they look cute :P
    ],
}