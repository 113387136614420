import React from 'react';
import {Box, Grid} from "@mui/material";

export default function Podcast({darkMode}) {
    const playerColorMode = darkMode ? "dark" : "light"
    const sourceUrl = `https://widget.spreaker.com/player?show_id=4206692&theme=${playerColorMode}&playlist=show&playlist-continuous=true&chapters-image=true&episode_image_position=right&hide-logo=false&hide-likes=false&hide-comments=false&hide-sharing=false&hide-download=true`
    return (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mt={ {xs: '1rem', md: '5rem'}}>
            <Box component={'section'}   alignItems={'center'} 
                width={{xs: '80%', md: '50%'}} borderRadius={'0.5rem'} >
                <iframe style={{ marginTop: '2.0rem' }} src={sourceUrl} width="100%" height="500px" frameBorder="0"></iframe>
            </Box>
        </Box>
    );
};