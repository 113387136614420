import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {Box} from "@mui/material";
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
// import me from '../../img/self.png';

const iconClass = "fa fa-circle";

function CustomServiceCard(props) {
   const { imagesrc } = props;
   const { title } = props;
   const { description } = props;
   const { titleColor } = props;
   const { clickAction } = props;
  
   return (
    <Box sx={{ 
        marginLeft: { xs: '3.0rem', md: '1.0rem' }, 
        marginRight:  { xs: '3.0rem', md: '1.0rem' }, 
        marginBottom:  { xs: '3.0rem', md: '1.0rem' }}}>
        <CardActionArea onClick={clickAction}>
           <Card sx={{width: { xs: 1/1, md: 345 },  height: { xs: 'auto', md: 500 } }}>
             <CardMedia
               component="img"
               height="250"
               image={imagesrc}
               alt={title}
             />
             <CardContent>
               <Typography gutterBottom variant="h5" component="div" color={titleColor}>
                 {title}
               </Typography>
               { description.split('\n').map( descriptionline => 
                   <Typography variant="body2" color="text.secondary">
                     {descriptionline}
                   </Typography>
               )} 
             </CardContent>
           </Card>
        </CardActionArea>
     </Box>
   );
}

export default CustomServiceCard;