import React from 'react';
import Style from './About.module.scss';
import CustomServiceCard from "./CustomServiceCard";
import {Box} from "@mui/material";
import {info, colors} from "../../info/Info";
import me from '../../img/self.png';





export default function About({darkMode}) {
    const baseImgFolder = '../../img/'
    const grupoTerapeuticoClick = () => {
        const hotmartLink = "https://chk.eduzz.com/2310437";
        window.open(hotmartLink)
    }
    return (
        <Box display={'flex'} width={'100%'} flexDirection={{xs: 'column', s: 'column', md: 'row', }} justifyContent={'center'} mt={ {xs: '3rem', md: '7rem'}}>
             <CustomServiceCard imagesrc={require('../../img/atendimento.png')} title={info.services[2].title} description={info.services[2].description}  titleColor={colors[1]} clickAction={grupoTerapeuticoClick}/>
             <CustomServiceCard imagesrc={require('../../img/constelacao.png')} title={info.services[1].title} description={info.services[1].description}  titleColor={colors[1]}/>
             <CustomServiceCard imagesrc={require('../../img/psico.png')} title={info.services[0].title} description={info.services[0].description} titleColor={colors[1]}/>
        </Box>
    )
}